import React from "react";
import BannerApp from "./../../imagenes/bannerAppMovil.png";

class BannerAppMovil extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="cta" className="cta">
          <div className="container" data-aos="zoom-in">
            <div className="row">
              <div className="col-lg-9 text-center text-lg-start">
                <img src={BannerApp} className="w-100 rounded" />
              </div>
              <div className="col-lg-3 d-flex flex-column justify-content-center align-items-center">
                <p>
                  <a
                    className="cta-btn align-middle"
                    href="https://play.google.com/store/apps/details?id=futuro.lamanence.fin"
                    target="_blank"
                  >
                    <i class="bi bi-android2"></i>
                    <label style={{ marginLeft: "10px" }}>Descargar</label>
                  </a>
                </p>
                <p>
                  <a
                    className="cta-btn align-middle"
                    href="https://apps.apple.com/ec/app/banca-m%C3%B3vil-futuro-lamanense/id6744178751?platform=iphone"
                    target="_blank"
                  >
                    <i class="bi bi-apple"></i>
                    <label style={{ marginLeft: "10px" }}>Descargar</label>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default BannerAppMovil;
